import React, {Component} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "@material-ui/core/Button"
import Lottie from "react-lottie"
import AlfieCircle from "../assets/animation/circle_animation"
import Modal from "@material-ui/core/Modal"
import TryItNowPopup from "../pages/tryitnow-popup"
import WaveSvg from "../assets/images/Wave.svg"
import ArrowForwardIcon from "@material-ui/icons/Forward"
import ArrowForwardThinIcon from "@material-ui/icons/ArrowForward"
import BreadCrumbs from "../components/bread-crumbs";
import "./how-it-works.scss";
import "./popup-cta.scss";

const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    }

]

class HowItWorks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            openModalGetStarted: false
        }
    }

    componentDidMount() {
        window.analytics.page("How it Works")
    }

    render() {

        return (
            <Layout location={this.props.location}>
                <SEO title="How it works"/>
                <BreadCrumbs currentPage={"How it Works"} breadCrumbsList={breadCrumbsList}/>
                <div className="how-start-header">
                    <div className="how-start-inner">
                        <div className="how-start-pattern-content">
                            <h2 className="how-start-main-head">How It
                                Works</h2>
                            <p className="how-start-sub-head">We’re an app-based
                                hub of resources and clinical providers
                                that help people thrive. </p>
                            <p className="how-start-sub-Text">Whatever you want,
                                and whatever your pace, we have
                                something
                                for you. Meet 1:1 with compassionate, highly
                                skilled therapists, prescribers, and
                                coaches – or
                                just chat on your own with our chatbot
                                Alfie.</p>
                            <p className="how-start-sub-Text">All of our
                                services are designed to protect your privacy
                                while giving you the support you need. </p>
                            <p className="dark">It’s also 100% judgement
                                free.</p>
                            <Button
                                className="blue-arrow-btn" color="primary"
                                href={"/get-started"}>
                                Get Started <ArrowForwardIcon/>
                            </Button>
                        </div>
                        <div className="how-start-image-side">
                            <img className="how-start-hero-img"
                                 style={{maxWidth: 500}}
                                 src={require(
                                     "../assets/images/about-hero-img.png")}
                                 alt="icon-iPhone-Visual"/>
                        </div>

                    </div>
                </div>

                <div className="how-common-wave-wrapper"
                     style={{backgroundImage: `url(${WaveSvg})`}}>
                    <div className="wave-bg">
                        <div className="how-common-wave-inner">
                            <div className="how-it-wave-wrapper"
                                 id="what-it-is">
                                <div className="how-it-wave-inner">
                                    <div className="what-head">
                                        <h2 className="what-main-head">What you
                                            can expect...</h2>
                                    </div>

                                    <div className="what-row">
                                        <div className="quote-side">
                                            <p className="main-text">It depends
                                                on what you want.<br/>
                                                You can:</p>

                                            <div className="quote-wrap">
                                                <img className='check-circle'
                                                     src={require(
                                                         '../assets/images/checkmark-circle-outlined-2.svg')}
                                                     alt="icon-checkmark-circle-chat-alfie"/>
                                                <p className="list-text">Chat
                                                    with Alfie and he’ll get to
                                                    know your
                                                    needs.</p>
                                            </div>
                                            <div className="quote-wrap">
                                                <img className='check-circle'
                                                     src={require(
                                                         '../assets/images/checkmark-circle-outlined-2.svg')}
                                                     alt="icon-checkmark-circle-connect-matchmaker"/>
                                                <p className="list-text">Connect
                                                    with a matchmaker to get
                                                    direction from
                                                    a real person. </p>
                                            </div>

                                            <div className="quote-wrap">
                                                <img className='check-circle'
                                                     src={require(
                                                         '../assets/images/checkmark-circle-outlined-2.svg')}
                                                     alt="icon-checkmark-circle-book-appointment"/>
                                                <p className="list-text">Book an
                                                    appointment to connect
                                                    directly with a
                                                    prescriber, therapist,
                                                    or coach.</p>
                                            </div>
                                        </div>
                                        <div className="img-side">
                                            <img className="what-img"
                                                 src={require(
                                                     "../assets/images/hero-iPhone-x-clay-3.png")}
                                                 alt="cion-sideimage"/>
                                        </div>
                                    </div>
                                    <div className="what-row">
                                        <div className="img-side">
                                            <img className="what-img"
                                                 src={require(
                                                     "../assets/images/hero-iPhone-x-clay-2.png")}
                                                 alt="icon-whatimg"/>
                                        </div>
                                        <div className="quote-side">
                                            <p className="main-text">We’ll
                                                assess your needs and help you
                                                identify your
                                                goals</p>
                                            <p className="sub-text">Whether
                                                you’re working with one of our
                                                clinicians or
                                                going through
                                                Conversational Courses with
                                                Alfie, we work with you to
                                                assess your
                                                situation and goals.
                                                This helps us provide you with
                                                the best care and
                                                resources. </p>
                                        </div>
                                    </div>
                                    <div className="what-row">
                                        <div className="quote-side">
                                            <p className="main-text">Ongoing
                                                support</p>
                                            <p className="sub-text">This might
                                                include ongoing sessions,
                                                medication
                                                management,
                                                exercises, reading materials,
                                                videos, and other
                                                resources. </p>
                                        </div>
                                        <div className="img-side">
                                            <img className="what-img"
                                                 src={require(
                                                     "../assets/images/hero-iPhone-x-clay.png")}
                                                 alt="icon-what"/>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>


                <div className="alfie-intro">
                    <div className="intro-con">
                        <p className="intro-sub-head">WHAT’S WITH THE DOG?</p>
                        <p className="intro-head">Meet Alfie</p>
                        <Lottie id="Alfie-Circle" options={{
                            loop: true,
                            autoplay: true,
                            animationData: AlfieCircle,
                        }}
                                height={145}
                                width={145}
                        />
                        <p className="intro-parah">Alfie, our Automated
                            Lifestyle Feedback Intelligence Engine (and the
                            cute
                            little dog you see throughout the app) represents a
                            culmination of two years of work to
                            create
                            a dynamic support system for managing behavioral
                            health and substance use.</p>
                        <p className="intro-parah">
                            Alfie was built by a passionate group of
                            technologists, epidemiologists, health economists,
                            behavioral health providers, patient advocates,
                            people using drugs, and family members.
                        </p>
                    </div>
                </div>

                <div className="how-con-wrapper" id="how-it-works">
                    <div className="how-con-inner">
                        <h2 className="blackHeading2">HOW IT WORKS</h2>
                        <div className="how-list">
                            <div id="single-how-item-1"
                                 className="single-how-item">
                                <p className="how-num">1</p>
                                <img src={require(
                                    "../assets/images/my-location.svg")}
                                     alt="icon-my-location"/>
                                <p className="how-des">Identify where you want
                                    to make a change.</p>
                            </div>
                            <div id="single-how-item-2"
                                 className="single-how-item">
                                <p className="how-num">2</p>
                                <img src={require(
                                    "../assets/images/test-tube.svg")}
                                     alt="iocn-test-tube"/>
                                <p className="how-des">Test personalized
                                    strategies designed to help you make those
                                    changes.</p>
                            </div>
                            <div id="single-how-item-3"
                                 className="single-how-item">
                                <p className="how-num">3</p>
                                <img src={require(
                                    "../assets/images/chart-donut-2.svg")}
                                     alt="icon-chart-donut"/>
                                <p className="how-des">Monitor your
                                    progress.</p>
                            </div>
                            <div id="single-how-item-4"
                                 className="single-how-item">
                                <p className="how-num">4</p>
                                <img src={require(
                                    "../assets/images/route-ico.svg")}
                                     alt="icon-route"/>
                                <p className="how-des">Amplify what works and
                                    leave the rest.</p>
                            </div>
                            <div id="single-how-item-5"
                                 className="single-how-item">
                                <p className="how-num">5</p>
                                <img src={require(
                                    "../assets/images/clinic-ico.svg")}
                                     alt="icon-clinic"/>
                                <p className="how-des">Access to clinical staff
                                    along the way for guidance, support, and
                                    additional insights.</p>
                            </div>
                            <div id="single-how-item-6"
                                 className="single-how-item">
                                <p className="how-num">6</p>
                                <img src={require(
                                    "../assets/images/award-ico.svg")}
                                     alt="icon-award"/>
                                <p className="how-des">Reach the goals that you
                                    define for yourself.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="why-wrapper" id="why-it-work">
                    <div className="why-con-wrap">
                        <h2 className="why-main-head">Why It Works</h2>

                        <div className="why-list">
                            <div className="single-why-item">
                                <div className="why-number">
                                    <p>1</p>
                                </div>
                                <div className="why-content">
                                    <h2 className="why-head">Our People</h2>
                                    <p className="why-parah">
                                        Confidant providers are compassionate
                                        and knowledgeable. They’re trained in
                                        the
                                        cutting-edge best practices around drug
                                        use, addiction and mental health – but
                                        they also see that the bigger picture.
                                        We won’t reduce you to your substance
                                        use.
                                        Instead, we align the right resources to
                                        help you achieve your version of
                                        success.
                                    </p>
                                </div>
                            </div>
                            <div className="single-why-item">
                                <div className="why-number">
                                    <p>2</p>
                                </div>
                                <div className="why-content">
                                    <h2 className="why-head">Personalized
                                        Care</h2>
                                    <p className="why-parah">
                                        What works for one person doesn’t always
                                        work for someone else.
                                        At Confidant, we believe in looking at
                                        the context and nuance within your life.
                                        This is called patient-centered care,
                                        and we’re champions of it. Cookie
                                        cutters
                                        are
                                        for people addicted to sugar.
                                    </p>
                                </div>
                            </div>
                            <div className="single-why-item">
                                <div className="why-number">
                                    <p>3</p>
                                </div>
                                <div className="why-content">
                                    <h2 className="why-head">Integrated
                                        Access</h2>
                                    <p className="why-parah">
                                        We designed Confidant to be as
                                        accessible as your vices. Get support on
                                        your
                                        schedule and work with people who
                                        understand that life doesn’t stop when
                                        you
                                        decide
                                        to start setting and achieving new
                                        goals. We don’t want to slow you down,
                                        but we promise to be there when you need
                                        us. Access our services any time,
                                        anywhere.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="new-community-model-wrapper">
                    <div className="new-community-model-inner">
                        <h2 className="new-community-model-text">Confidant Community Payment Model</h2>
                        <p className="community-payment-description">Our "Pay it Forward" approach enables people to access our services for as little as $1 Because your income shouldn't dictate your future.</p>
                        <Button href="/community-payment/" className="new-blue-link" color="primary">
                            Read about the community payment model
                        </Button>
                        <img
                            className="community-model-image"
                            src={require("../assets/images/community-image1.png")}
                            alt="Community Model Image"
                        />
                    </div>

                </div>

                <div className="life-step-main">
                    <div className="span-content">
                        <img src={require("../assets/images/doglake.png")}
                             alt="icon-doglake"/>
                        <h2 className="life-step-head">Step into the life you
                            actually want.</h2>
                        <p className="life-step-text2">The only thing you’ll pay
                            for
                            are the sessions you choose
                            to book with clinical providers. And because we’ve
                            formed our own network, the rates are
                            better than you’ll find elsewhere.
                        </p>

                        <Button href="/get-started/" className="new-blue-link"
                                color="primary">
                            Try it now<ArrowForwardThinIcon/>
                        </Button>
                    </div>
                </div>

                <Modal
                    className="modalStyle1"
                    open={this.state.openModal || (typeof window !== "undefined"
                        && window.location && window.location.hash
                        === "#try-it-now")}
                    disableScrollLock={true}>
                    <TryItNowPopup/>
                </Modal>
                <Modal
                    className="modalStyle1"
                    open={this.state.openModalGetStarted || (typeof window
                        !== "undefined" && window.location
                        && window.location.hash === "#get-started")}
                    disableScrollLock={true}>
                </Modal>

            </Layout>
        )
    }
}

export default HowItWorks
